import { ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#7388A6",
    paper: "#FAFAFA",
  },
  primary: {
    main: "#7388A6",
    contrastText: "#FAFAFA",
  },
  secondary: {
    main: "#FF844B",
    light: "#FAFAFA",
    contrastText: "#FAFAFA",
  },
  error: {
    main: "#FF844B",
  },
  info: {
    main: "#FF844B",
  },
  warning: {
    main: "#FF844B",
  },
  success: {
    main: "#FF844B",
  },
  text: {
    primary: "#04121E",
    secondary: "#6E6E73",
  },
};

export default palette;
