import React from "react";
import { Box, Link, Typography } from "@mui/material";

import { PageLayout } from "@APP/components";

const PrivacyNoticeScreen = () => {
  return (
    <PageLayout>
      <Box maxWidth={980} alignSelf="center" id="privacyPolicyBox">
        <Typography variant="h5">Privacy Notice</Typography>
        <br />
        <Typography variant="body2">
          This privacy notice tells you how your personal data is used and protected when you access
          the Revenu app and use the Revenu services (including the Revenu websites and Revenu
          platform operated by us) (together &ldquo;<strong>Revenu Services</strong>&rdquo;) and
          tells you about your privacy rights and how the law protects you.
        </Typography>
        <br />
        <Typography variant="body2">
          In this privacy notice when we say &ldquo;<strong>BankiFi</strong>&rdquo; or &ldquo;
          <strong>we</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo; or &ldquo;
          <strong>our</strong>&rdquo; we mean BankiFi Technology Limited. Our registered company
          number is 10696778. Our registered office is Centurion House, 129 Deansgate, Manchester,
          M3 3WR and we can be contacted at <strong>revenu.support@bankifi.com</strong>. BankiFi is
          the data controller responsible for your personal data.
        </Typography>
        <br />
        <Typography variant="body2">
          When we say &ldquo;you&rdquo; or &ldquo;your&rdquo;, we mean the person whose personal
          data we process as a result of that person or another person&rsquo;s use of the Revenu
          Services.
        </Typography>
        <br />
        <Typography variant="body1">1. KEY INFORMATION AND WHO WE ARE</Typography>
        <Typography variant="body1">2. THE PERSONAL DATA WE COLLECT</Typography>
        <Typography variant="body1">3. HOW WE COLLECT YOUR PERSONAL DATA</Typography>
        <Typography variant="body1">4. HOW WE USE YOUR PERSONAL DATA</Typography>
        <Typography variant="body1">5. PURPOSES FOR WHICH WE USE YOUR PERSONAL DATA</Typography>
        <Typography variant="body1">6. HOW WE SHARE YOUR PERSONAL DATA</Typography>
        <Typography variant="body1">7. INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA</Typography>
        <Typography variant="body1">8. DATA SECURITY</Typography>
        <Typography variant="body1">9. KEEPING YOUR PERSONAL DATA</Typography>
        <Typography variant="body1">10. YOUR LEGAL RIGHTS</Typography>
        <br />
        <Typography variant="subtitle1">1. KEY INFORMATION</Typography>
        <br />
        <Typography variant="body2">
          Other privacy documents: It is important that you read this privacy notice together with
          any other privacy notice or fair processing policy we provide to you on specific occasions
          when we collect or process personal data about you so that you are aware of how and why we
          use your personal data.
        </Typography>
        <br />
        <Typography variant="body2">
          Contacting our data protection representative: We have appointed a data protection
          representative who is responsible for overseeing questions about this privacy notice. If
          you have any questions about this privacy notice, including any requests to exercise your
          legal rights, please contact the data protection representative using the details set out
          below:
        </Typography>
        <br />
        <Typography variant="body2">
          Email address: <strong>revenu.support@bankifi.com</strong>
        </Typography>
        <Typography variant="body2">
          Postal address: Bankifi Technology Limited, Beehive Lofts, Jersey Street, Manchester, M4
          6JG
        </Typography>
        <br />
        <Typography variant="body2">
          Contacting the UK data protection regulator: We hope you will let us have an opportunity
          to deal with any concerns about our use of your personal data. However, you have the right
          to make a complaint at any time to the Information Commissioner's Office &ndash; the UK
          regulator for data protection issues (
          <Link href="http://www.ico.org.uk">www.ico.org.uk</Link>
          ).
        </Typography>
        <br />
        <Typography variant="body2">
          Updating our privacy notice: We keep our privacy notice under regular review. This version
          was last updated on <strong>28.01.2022</strong>. You can obtain older versions by
          contacting us.
        </Typography>
        <br />
        <Typography variant="body2">
          Links to other services: The Revenu Services may include links to third-party websites,
          plug-ins and applications. Clicking on those links or enabling those connections may allow
          third parties to collect or share personal data about you. We do not control these
          third-party services and are not responsible for their privacy notices and arrangements.
          When you leave the Revenu Services, we encourage you to read the privacy notice of every
          service you visit.
        </Typography>
        <br />
        <Typography variant="subtitle1">2. THE PERSONAL DATA WE COLLECT</Typography>
        <br />
        <Typography variant="body2">
          Personal data, or personal information, means any information about an individual that can
          be used to identify that person. It does not include data where the identity has been
          removed (anonymous data).
        </Typography>
        <br />
        <Typography variant="body2">
          We may collect, use, store and transfer different kinds of personal data about you or any
          person whose personal data you provide to us, and which we have grouped together as
          follows:
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Identity Data</strong> includes first name, last name, username or similar
          identifier, title.
        </Typography>
        <Typography variant="body2">
          <strong>Contact Data</strong> includes business name and contact details, billing address,
          email address and telephone numbers.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Transaction Data</strong> includes details about payments to and from you and
          other transaction details from your use of Revenu Services.
        </Typography>
        <Typography variant="body2">
          <strong>Technical Data</strong> includes internet protocol (IP) address, your login data,
          browser type and version, time zone setting and location, browser plug-in types and
          versions, operating system and platform, and other technology on the devices you use to
          access Revenu Services.
        </Typography>
        <Typography variant="body2">
          <strong>Profile Data</strong> includes your username and password, purchases or orders
          made by you, your interests, preferences, feedback and survey responses.
        </Typography>
        <Typography variant="body2">
          <strong>Usage Data</strong> includes information about how you use Revenu Services.
        </Typography>
        <Typography variant="body2">
          <strong>Marketing and Communications Data</strong> includes your preferences in receiving
          marketing from us and our third parties and your communication preferences.
        </Typography>
        <br />
        <Typography variant="body2">
          We also collect, use and share <strong>Aggregated Data</strong> such as statistical or
          demographic data for any purpose. Aggregated Data could be derived from your personal data
          but is not personal data in law as it will not directly or indirectly reveal your
          identity. For example, we may aggregate your Usage Data to calculate the percentage of
          users accessing a specific part of the Revenu Services. However, if we combine or connect
          Aggregated Data with your personal data so that it can directly or indirectly identify
          you, we treat the combined data as personal data which will be used in accordance with
          this privacy notice.
        </Typography>
        <br />
        <Typography variant="body2">
          We do not collect any Special Categories of Personal Data about you (this includes details
          about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
          orientation, political opinions, trade union membership, information about your health,
          and genetic and biometric data). Nor do we collect any information about criminal
          convictions and offences.
        </Typography>
        <br />
        <Typography variant="body2">
          The Revenu Services are not intended for children, and we do not knowingly collect
          personal data relating to children.
        </Typography>
        <br />
        <Typography variant="subtitle1">3. HOW WE COLLECT YOUR PERSONAL DATA</Typography>
        <br />
        <Typography variant="body2">
          We collect your personal data in the following ways:
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Direct engagement with us:</strong> This is where you or another user give us your
          personal data by filling in forms on the Revenu Services or by corresponding with us by
          post, phone, email or otherwise. This includes personal data you provide when you:
        </Typography>
        <Typography variant="body2">
          <br />
          <ul style={{ listStyle: "inside" }}>
            <li>apply for our products or services or subsequently use them.</li>
            <li>create an account on the Revenu Services.</li>
            <li>subscribe to any Revenu Services or publications.</li>
            <li>request marketing to be sent to you.</li>
            <li>enter a competition, promotion or survey; or</li>
            <li>give us feedback or contact us.</li>
          </ul>
          <br />
        </Typography>
        <Typography variant="body2">
          <strong>Automated technologies:</strong> When you interact with the Revenu Services, we
          will automatically collect Technical Data about your equipment, browsing actions and
          patterns. We collect this personal data by using server logs and other similar
          technologies.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Information obtained from third parties or publicly available sources:</strong> We
          receive personal data about you from various third parties and public sources as set out
          below:
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Technical Data</strong> from analytics providers [such as Google based outside of
          the UK]
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Identity</strong> and <strong>Contact Data</strong> from publicly available
          sources such as Companies House and the Electoral Register based inside the UK.
        </Typography>
        <br />
        <Typography variant="subtitle1">4. HOW WE USE YOUR PERSONAL DATA</Typography>
        <br />
        <Typography variant="body2">
          We only use your personal data when the law allows us to. Most commonly, we use your
          personal data in the following circumstances:
        </Typography>
        <Typography variant="body2">
          <br />
          <ul style={{ listStyle: "inside" }}>
            <li>
              where we need to use it to perform a contract we are about to enter or have entered
              with you.
            </li>
            <li>
              where it is necessary for our legitimate interests (or those of a third party) and
              your interests and fundamental rights do not override those interests.
            </li>
            <li>where we need to comply with a legal obligation.</li>
          </ul>
          <br />
        </Typography>
        <Typography variant="body2">
          In most circumstances the legal basis we rely on to process your personal data is that it
          is necessary for our legitimate interests (or those of a third party). Where that is the
          case, the relevant legitimate interests are:
        </Typography>
        <Typography variant="body2">
          <br />
          <ul style={{ listStyle: "inside" }}>
            <li>our legitimate interests in complying with our contractual obligations to you</li>
            <li>
              our legitimate interests in complying with our statutory and regulatory obligations,
              and the requirements of our regulators
            </li>
            <li>our legitimate interests in keeping our records updated</li>
            <li>
              our legitimate interests in assessing how customers use the Revenu Services, and our
              products/services available through them, and improving marketing and
              products/services offered to our customers
            </li>
            <li>
              our legitimate interests in running our business, administering IT services used
              within it, managing network security, and preventing fraud
            </li>
          </ul>
          <br />
        </Typography>
        <Typography variant="body2">
          We do not rely on consent as a legal basis for processing your personal data other than
          where it is to send third party direct marketing communications to you by email or text
          message. You have the right to withdraw consent to marketing at any time by contacting us.
        </Typography>
        <br />
        <Typography variant="subtitle1">5. PURPOSES FOR WHICH WE USE YOUR PERSONAL DATA</Typography>
        <br />
        <Typography variant="body2">
          The following purposes are those for which we use your personal data. We may use the same
          piece of personal data for more than one purpose.
        </Typography>
        <br />
        <Typography variant="body2">
          <br />
          <ul style={{ listStyle: "inside" }}>
            <li>To register you as a new customer</li>
            <li>
              To process and deliver your requests (and those of others you have a transactional
              relationship with) for our products and/or services through the Revenu Services
            </li>
            <li>
              To manage our relationship with you, including by telling you about changes to our
              terms or privacy policy, or asking you to leave a review or take a survey
            </li>
            <li>To enable you to take part in a prize draw or competition</li>
            <li>
              To administer and protect our business and the Revenu Services (including
              troubleshooting, data analysis, testing, system maintenance, support, reporting and
              hosting of data)
            </li>
            <li>
              To deliver relevant content and advertisements to you and to measure or understand the
              effectiveness of the advertising we serve to you
            </li>
            <li>
              To use data analytics to improve the Revenu Services, and/or marketing, customer
              relationships, or experiences.
            </li>
            <li>
              To make recommendations about goods or services that may be of interest to you (we
              will get your express opt-in consent before we share your personal data with any third
              party for marketing purposes).
            </li>
          </ul>
          <br />
        </Typography>
        <Typography variant="body2">
          You can ask us or third parties to stop sending you marketing messages at any time by
          following the opt-out links on any marketing message sent to you or by contacting us.
        </Typography>
        <br />
        <Typography variant="body2">
          We will only use your personal data for the purposes for which we collected it, unless we
          reasonably consider that we need to use it for another reason and that reason is
          compatible with the original purpose.
        </Typography>
        <br />
        <Typography variant="body2">
          If we need to use your personal data for an unrelated purpose, we will notify you and we
          will explain the legal basis which allows us to do so or seek your consent if a suitable
          legal basis does not apply.
        </Typography>
        <br />
        <Typography variant="body2">
          We may process your personal data without your knowledge or consent where this is required
          or permitted by law.
        </Typography>
        <br />
        <Typography variant="subtitle1">6. HOW WE SHARE YOUR PERSONAL DATA</Typography>
        <br />
        <Typography variant="body2">
          We may share your personal data with the following third parties:
        </Typography>
        <Typography variant="body2">
          <br />
          <ul style={{ listStyle: "inside" }}>
            <li>
              internal third parties &ndash; other companies in the same group of companies as
              Bankifi who are based in the UK and provide IT and system administration services.
            </li>
            <li>external third parties:</li>
            <ul style={{ paddingLeft: 20 }}>
              <li>
                service providers (acting as data processors) who provide IT and system
                administration services.
              </li>
              <li>
                professional advisers including lawyers, bankers, auditors and insurers who provide
                consultancy, banking, legal, insurance and accounting services];
              </li>
              <li>
                HM Revenue &amp; Customs, regulators and other authorities who require reporting of
                processing activities in certain circumstances.
              </li>
              <li>fraud prevention agencies.</li>
              <li>
                third parties to whom we may choose to sell, transfer or merge parts of our business
                or our assets.
              </li>
              <li>
                TSB bank for the purpose of improving the quality and access to products and
                services provided.
              </li>
            </ul>
          </ul>
          <br />
        </Typography>
        <Typography variant="body2">
          We require all third parties to respect the security of your personal data and to treat it
          in accordance with the law. We do not allow third-party service providers to use your
          personal data for their own purposes and only permit them to process your personal data
          for specified purposes and in accordance with our instructions.
        </Typography>
        <br />
        <Typography variant="subtitle1">
          7. INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA
        </Typography>
        <br />
        <Typography variant="body2">
          We do not transfer your personal data outside of the UK
        </Typography>
        <br />
        <Typography variant="subtitle1">8. DATA SECURITY</Typography>
        <br />
        <Typography variant="body2">
          We put in place appropriate security measures to minimise the risk of your personal data
          from being accidentally lost, used or accessed in an unauthorised way, altered or
          disclosed. We limit access to your personal data to those personnel who have a business
          need to know. They only process your personal data on our instructions and are subject to
          a duty of confidentiality. We have put in place procedures to deal with any suspected
          personal data breach and will notify you and any applicable regulator of a breach where we
          are legally required to do so.
        </Typography>
        <br />
        <Typography variant="body2">
          We are ISO27001 accredited, which means that we adhere to proper policies and procedures
          in protecting all information we hold, including personal data.
        </Typography>
        <br />
        <Typography variant="subtitle1">9. KEEPING YOUR PERSONAL DATA</Typography>
        <br />
        <Typography variant="body2">
          We will only keep your personal data for as long as reasonably necessary to fulfil the
          purposes we collected it for, including to satisfy any legal, regulatory, tax, accounting
          or reporting requirements. We may retain your personal data for a longer period if there
          is a complaint or if we reasonably believe there is a prospect of litigation in respect to
          our relationship with you.
        </Typography>
        <br />
        <Typography variant="body2">
          We consider the amount, nature and sensitivity of the personal data, and the potential
          risk of harm from unauthorised use or disclosure in determining the appropriate retention
          period for personal data.
        </Typography>
        <br />
        <Typography variant="body2">
          Details of retention periods for different aspects of your personal data are available in
          our retention policy which you can request by contacting us.
        </Typography>
        <br />
        <Typography variant="subtitle1">10. YOUR LEGAL RIGHTS</Typography>
        <br />
        <Typography variant="body2">
          You have the following rights under data protection law in relation to your personal data.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>
            Request access to your personal data (&lsquo;data subject access request&rsquo; or
            &lsquo;DSAR&rsquo;):
          </strong>{" "}
          This allows you to receive a copy of the personal data we hold about you and to confirm
          information showing that we are lawfully processing it.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Request correction of your personal data: </strong>This allows you to have us
          correct or update any incomplete or inaccurate data we hold about you (subject to us
          verifying the accuracy of the new data).
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Request erasure of your personal data: </strong>This allows you to ask us to
          delete or remove personal data if we have no good reason for continuing to process it,
          where you have successfully exercised your right to object to processing, where we may
          have processed your information unlawfully, or where we have to erase your personal data
          to comply with local law. We may not always be able to comply with your request for
          specific legal reasons which we will notify to you, if applicable, at the time. If you
          wish to make this type of request please contact us at revenu.erasure@bankifi.com.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Object to processing of your personal data: </strong>This allows you to object to
          us processing your personal data where we are relying on a legitimate interest (or those
          of a third party) and there is something in your circumstances which makes you want to
          object to processing on this ground as it impacts on your fundamental rights and freedoms.
          This also allows you to object where we are processing your personal data for direct
          marketing purposes. In some cases, we may demonstrate that we have compelling legitimate
          grounds to process your information which override your rights and freedoms.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Request restriction of processing your personal data: </strong>This allows you to
          ask us to suspend the processing of your personal data where: (i) you want us to establish
          the data's accuracy; (ii) our use of the data is unlawful but you do not want us to erase
          it; (iii) you need us to hold the data even if we no longer require it as you need it to
          establish, exercise or defend legal claims; (iv) you have objected to our use of your data
          but we need to verify whether we have overriding legitimate grounds to use it.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Request transfer of your personal data: </strong>This allows you to ask us to send
          you, or a third party you nominate, your personal data in a structured, commonly used,
          machine-readable format. This only applies to automated information which you initially
          provided consent for us to use or where we used the information to perform a contract with
          you.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>Right to withdraw consent: </strong>This allows you to ask us to stop processing
          your personal data where we are relying on your consent to process it. This will not
          affect the lawfulness of any processing carried out before you withdraw your consent. If
          you withdraw your consent, we may not be able to provide certain products or services to
          you. We will advise you if this is the case at the time you withdraw your consent.
        </Typography>
        <br />
        <Typography variant="body2">
          If you wish to exercise any of the rights set out above, please contact us.
        </Typography>
        <br />
        <Typography variant="body2">
          You do not have to pay a fee to exercise your rights over your personal data. However, if
          your request is clearly unfounded, repetitive or excessive we are allowed to refuse to
          comply with it. If that is the case, we may be prepared to comply with your request if you
          pay a reasonable fee.
        </Typography>
        <br />
        <Typography variant="body2">
          We may need specific information from you to help us confirm your identity before
          complying with any request to exercise your rights over your personal data. This is to
          ensure we do not disclose that personal data to any person who does not have the right to
          receive it. We may also ask you for further information about your request to speed up our
          response.
        </Typography>
        <br />
        <Typography variant="body2">
          We try to respond to all legitimate requests within one month. If we are allowed to take
          longer (e.g., because your request is particularly complex, or you have made a number of
          requests) we will notify you and keep you updated of the timeframe for our response.
        </Typography>
        <br />
        <Typography variant="body2">Version 2.0</Typography>
        <Typography variant="body2">January 2022</Typography>
        <br />
      </Box>
    </PageLayout>
  );
};

export default PrivacyNoticeScreen;
